
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.contact {
  .cards-grid-inner {
    @include mq(l) {
      > div {
        width: calc(50% - col(1, 24));
        margin-inline: col(0.5, 24);
      }
    }
  }
}
.contact__details-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16rem;

  @include mq(m) {
    flex-direction: row;
  }
}

.contact__details__form {
  width: 100%;

  @include mq(m) {
    margin-left: col(1, 24);
  }
}

.contact__details__sales {
  padding: 4rem;
  width: 100%;
  background-color: $c-black-20;
  border-radius: 2.4rem;

  @include mq($until: m) {
    margin-top: 3rem;
  }

  @include mq(m) {
    // position: sticky;
    // top: 10rem; // Check Header
    margin-right: col(1, 24);
    flex: 1 0 auto;
    margin-left: col(2, 24);
    width: col(8, 24);
  }
}

.contact__locations {
  padding: 12rem 0;
  background-color: $c-black-20;
}

.contact__locations__title {
  margin-bottom: 10rem;
  color: $c-dark-blue;
  text-align: center;
}

.contact__locations__card {
  ::v-deep {
    @include mq(l) {
      width: calc(50% - col(2, 24));
      margin-inline: col(1, 24);
    }
  }
}
