
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































.sales__headline {
  font-size: 1.3rem;
  text-transform: none;
}

.sales__title {
  margin-bottom: 5rem;
}

.sales__phone {
  color: rgba($c-black, 0.5);
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.sales__item {
  display: flex;
  align-items: flex-start;
}

.sales__item + .sales__item {
  margin-top: 2rem;
  padding-top: 3rem;
  border-top: 1px solid #e6e6e6;
}

.sales__content {
  flex: 1;
}

.sales__country {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
}

.sales__timezone {
  @extend %tag;

  margin-right: 0;
  display: inline-flex;
  background-color: $c-white;
}
