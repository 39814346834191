
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































.location-card {
  ::v-deep {
    .location-card__btn {
      background-color: $c-button;
    }
  }

  @include mq($until: l) {
    ::v-deep {
      .location-card__btn {
        padding: 1.8rem;
        line-height: 1;
      }

      .action__icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}

.location-card-inner {
  position: relative;
  overflow: hidden;
  border-radius: 2.4rem;

  @include mq(m) {
    padding-bottom: 15rem;
  }
}

.location-card__content {
  @include fluid(
    padding,
    (
      s: 30px,
      xl: 48px,
    )
  );

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: $c-white;
  border-radius: 2.4rem;
  transform: translateY(-40px);
  transition: 0.5s $ease-out-quad;

  @include mq(m) {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(0);
  }
}

.location-card__header {
  display: flex;
  align-items: center;
}

.location-card__title {
  @include fluid(
    font-size,
    (
      s: 20px,
      xl: 24px,
    )
  );

  color: $c-dark-blue;
}

.location-card__hq {
  @extend %tag;

  margin-right: 0;
  margin-left: 1rem;
}

.location-card__description {
  div {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $c-black-80;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
  }
}

.location-card__picture-outer {
  @include aspect-ratio(54, 44);
}

.location-card__picture {
  @include get-all-space;

  overflow: hidden;
  transition: 0.5s $ease-out-quad;

  img {
    @include get-all-space;

    object-fit: cover;
    transform: scale(1.2);
    transition: 0.3s $ease-softer;
  }

  .location-card.active & {
    img {
      transform: scale(1);
    }
  }
}

.location-card__btn-wrapper {
  display: inline-flex;
  margin-top: 2rem;
}
